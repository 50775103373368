<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลค่าคอมมิชชั่น
      </v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" style="overflow: auto" class="pl-4 pb-4">
          <v-card
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table
                :headers="headers"
                :items="commission"
                :loading="loading"
            >
              <template #item.ref="{ item }">
                <router-link :to="goRef(item).link" target="_blank">{{ goRef(item).text }}</router-link>
              </template>

              <template v-slot:item.name="{item}">
                {{ showName(item) }}
              </template>

              <template class="text-right" v-slot:item.price="{item}">
                {{ $currency(item.price).format() }}
              </template>

              <template v-slot:item.is_paid="{item}">
                <v-checkbox disabled :input-value="item.is_paid"></v-checkbox>
              </template>

              <template slot="body.append">
                <tr style="background-color: #eaf8ff">
                  <td>
                    รวม
                  </td>
                  <td></td>
                  <td class="text-left"><b>{{ $currency(sumTotalPrice).format() }} บาท</b></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>

            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard
              :search-option="findHeaders"
              @search="getCommission"
              date

          />
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import axios from "axios";
import FilterCard from "@/components/FilterCard";

export default {
  components: {Splitpanes, Pane, FilterCard},
  name: "SalaryUserList",
  data: () => ({
    showFilter: true,
    commission: [],
    headers: [
      {
        text: 'วันที่',
        value: 'date'
      },
      {
        text: 'รายการ',
        value: 'name',
        align: 'center'
      },
      {
        text: 'ค่าคอมมิชชั่น',
        value: 'price'
      },
      {
        text: 'อ้างอิง',
        value: 'ref'
      },
      {
        text: 'จ่ายแล้ว',
        value: 'is_paid'
      }
    ],
    findHeaders: [
      {
        text: 'หมายเลขออเดอร์',
        value: 'order_id',
        align: 'center'
      },
      {
        text: 'หมายเลข PO',
        value: 'po_id',
        align: 'center'
      },
    ],
    sumTotalPrice: 0,
    loading: false
  }),
  created() {
    // this.getCommission()
  },
  methods: {
    showName(item) {
      if (item.po !== null) {
        return "รับสินค้า"
      } else if (item.order !== null) {
        return "ออเดอร์"
      }
    },
    getCommission(params) {
      this.loading = true
      axios.get(`api/tracks/commission/${this.$route.params.id}/${params}`)
          .then(res => {
            this.sumTotalPrice = 0
            this.commission = res.data.data
            this.commission.forEach(c => {
              this.sumTotalPrice += parseFloat(c.price)
            })
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
    goRef(item) {
      if (item.order !== null) {
        return {
          link: '/order/' + item.order,
          text: 'ดูออเดอร์ ' + item.order_display
        }
      } else if (item.po_id !== null) {
        return {
          link: '/stock/po/' + item.po,
          text: 'ดู PO ' + item.po
        }
      }
    },
  }
}
</script>

<style scoped>

</style>